import React from "react"
import PropTypes from "prop-types"
import Breadcrumb from "./Breadcrumb"
import Title from "./Title"

const PageHeader = ({ title, subtitle, isExcluded }) => (
  <section className="section page-header relative py-0">
    <div className="absolute inset-y-0 right-0 w-1/3 bg-primary-lighter h-11/10 lg:h-6/5"></div>
    <div className="relative container mx-auto px-4 py-16">
      <div className="absolute inset-y-0 left-1/2 lg:left-auto lg:right-0 w-screen bg-primary transform -translate-x-1/2 lg:translate-x-0"></div>
      <div className="relative lg:px-24">
        <Breadcrumb />
        <Title
          title={title}
          titleClass="mb-0 lg:mb-12"
          subtitle={subtitle}
          isExcluded={isExcluded}
        />
      </div>
    </div>
  </section>
)

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

PageHeader.defaultProps = {
  subtitle: null,
}

export default PageHeader
