import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import ContactListItem from "../components/ListItems/ContactListItem"
import PageHeader from "../components/PageHeader"


const AssociationsPage = ({location}) => {
  const data = useStaticQuery(graphql`
    {
      contacts: allNodeContact(
        filter: { status: { eq: true } }
        sort: { fields: created, order: DESC }
      ) {
        nodes {
          id
          title
          body {
            value
          }
          address: field_adresse {
            value
          }
          phone: field_telephone
          email: field_email
          links: field_links {
            title
            uri
          }
          image: field_image {
            alt
          }
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 360, quality: 75, layout: CONSTRAINED)
                }
              }
            }
            ages: field_ages {
              tid: drupal_internal__tid
            }
            needs: field_needs {
              tid: drupal_internal__tid
            }
            activity_areas: field_activity_zones {
              tid: drupal_internal__tid
            }
          }
        }
      }
      agesTerms: allTaxonomyTermAges(filter: { status: { eq: true } }) {
        edges {
          node {
            tid: drupal_internal__tid
            name
          }
        }
      }
      needsTerms: allTaxonomyTermNeeds(filter: { status: { eq: true } }) {
        edges {
          node {
            tid: drupal_internal__tid
            name
          }
        }
      }
      activityAreasTerms: allTaxonomyTermActivityAreas(
        filter: { status: { eq: true } }
      ) {
        edges {
          node {
            tid: drupal_internal__tid
            name
          }
        }
      }
    }
  `)

  return (
    <Layout contentType="list__associations">
      <Metas title="Mes associations" location={location}/>
      <PageHeader title="Mes associations" />
      <section className="section page-content">
        <div className="container mx-auto px-4">
          {data.contacts.nodes.length > 0 ? (
            <div className="columns grid grid-cols-1 lg:grid-cols-3 gap-8">
              {data.contacts.nodes.map((node, index) => (
                <div key={index} className="column contact">
                  <ContactListItem content={node} />
                </div>
              ))}
            </div>
          ) : (
            <>Aucun résultat pour le moment ...</>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default AssociationsPage
