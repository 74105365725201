import React, { useContext } from "react"
import PropTypes from "prop-types"
import { ModalContext } from "../../contexts/ModalContext"
import { ModalContact } from "../Modal/ModalContact"
import HtmlParser from "../HtmlParser"

const ContactListItem = ({ content }) => {
  const { setModalVisibility, setCloseBtnVisibility, setModalContent } =
    useContext(ModalContext)

  const clickHandler = () => {
    setModalContent(<ModalContact data={content} />)
    setCloseBtnVisibility(false)
    setModalVisibility(true)
  }

  return (
    <article className="contact-list-item h-full border border-primary">
      <div
        className="list-item-content h-full p-8 xl:p-12 hover:bg-primary-lighter hover:bg-opacity-40 cursor-pointer"
        onClick={clickHandler}
      >
        <div className="title h3 mb-8">
          <span data-typesense-field="list_item">{content.title}</span>
        </div>

        {content.address && (
          <div className="contact-address flex mt-4" aria-label="Adresse">
            <div
              className="icon flex-initial w-4 lg:w-8 mr-4 text-primary-light text-xl"
              aria-hidden="true"
            >
              <span className="fas fa-map-marker-alt"></span>
            </div>
            <div className="content flex-1">
              <HtmlParser html={content.address.value} />
            </div>
          </div>
        )}

        {content.phone && (
          <div
            className="contact-phone flex mt-4"
            aria-label="Numéro de téléphone"
          >
            <div
              className="icon flex-initial w-4 lg:w-8 mr-4 text-primary-light text-xl"
              aria-hidden="true"
            >
              <span className="fas fa-phone"></span>
            </div>
            <div>{content.phone}</div>
          </div>
        )}

        <button
          className="button button-primary sr-only"
          onClick={clickHandler}
        >
          En savoir plus
        </button>
      </div>
    </article>
  )
}

ContactListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default ContactListItem
