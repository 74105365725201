import React, { useContext } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import HtmlParser from "../HtmlParser"
import { ModalContext } from "../../contexts/ModalContext"
import { classNames } from "../../Utils/functions"

export const ModalContact = ({ data }) => {
  const { setModalTitle, setModalVisibility, setCloseBtnVisibility } =
    useContext(ModalContext)

  const clickHandler = () => {
    setCloseBtnVisibility(true)
    setModalVisibility(false)
  }

  const keyPressHandler = event => {
    if (event.key === "Enter") {
      clickHandler()
    }
  }

  setModalTitle(data.title)

  return (
    <div className="modal-contact relative w-full border border-primary bg-white p-4 lg:p-8 xl:p-12">
      <div className="media flex flex-wrap">
        {data.relationships.image?.localFile && (
          <div className="media-left flex-initial w-full lg:w-1/3 mb-8">
            <GatsbyImage
              image={
                data.relationships.image.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={data.image.alt}
            />
          </div>
        )}
        <div
          className={classNames(
            data.relationships.image?.localFile && "lg:px-8",
            "media-content flex-1 mb-8"
          )}
        >
          <div className="title h3 mb-4" area-hidden="true">
            <span>{data.title}</span>
          </div>

          {data.address && (
            <div className="contact-address flex mt-4" aria-label="Adresse">
              <div
                className="icon flex-initial w-4 lg:w-8 mr-4 text-center text-primary-light text-xl"
                aria-hidden="true"
              >
                <span className="fas fa-map-marker-alt"></span>
              </div>
              <div className="content flex-1">
                <HtmlParser html={data.address.value} />
              </div>
            </div>
          )}

          {data.phone && (
            <div
              className="contact-phone flex mt-4"
              aria-label="Numéro de téléphone"
            >
              <div
                className="icon flex-initial w-4 lg:w-8 mr-4 text-center text-primary-light text-xl"
                aria-hidden="true"
              >
                <span className="fas fa-phone"></span>
              </div>
              <div>{data.phone}</div>
            </div>
          )}

          {data.email && (
            <div className="contact-email flex mt-4" aria-label="Adresse Email">
              <div
                className="icon flex-initial w-4 lg:w-8 mr-4 text-center text-primary-light text-xl"
                aria-hidden="true"
              >
                <span className="fas fa-envelope"></span>
              </div>
              <div>
                <a href={`mailto:${data.email}`}>{data.email}</a>
              </div>
            </div>
          )}
        </div>
      </div>
      {data.body && (
        <div className="contact-body mb-8">
          <div className="content">
            <HtmlParser html={data.body.value} />
          </div>
        </div>
      )}
      {data.links && data.links.length > 0 && (
        <div className="contact-links">
          <div className="title h4">
            <span>Plus d&apos;informations</span>
          </div>
          <div className="flex flex-col lg:flex-row flex-wrap lg:items-center -mx-2">
            {data.links.map((link, index) => (
              <div key={index} className="px-2 mb-2">
                <a
                  href={link.uri}
                  className="button button-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.title}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
      <div
        className="close absolute top-0 right-0 flex items-center justify-center h-12 w-12 m-4 lg:m-6 bg-blue-night text-white text-xl rounded-full cursor-pointer"
        onClick={clickHandler}
        onKeyPress={keyPressHandler}
        role="button"
        tabIndex={0}
      >
        <i className="fas fa-times" aria-hidden="true" />
        <span className="sr-only">Fermer</span>
      </div>
    </div>
  )
}
